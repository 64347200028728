const palette = {
  primary: { main: '#EC0000' },
  secondary: { main: '#444444' },
  inputBackground: { main: '#F5F9FB' },
  inputBorder: {
    main: '#257FA4',
    light: '#DEEDF2',
  },
  inputText: {
    main: '#767676',
  },
  checkbox: { main: '#63BA68' },
  gray: { main: '#f0f0f0' },
  white: { main: '#ffffff' },
};

export default palette;
