import React from 'react';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import EnrollmentForm from '../components/EnrollmentForm';
import { Title } from '../components/Layout';
import TermsAndConditionsDocument from '../components/TermsAndConditionsDocument';
import { TERMS_AND_CONDITIONS_TITLE } from '../constants/terms-and-conditions';
import { ENROLMENT_TITLE, ENROLLMENT_BODY } from '../constants/enrollment';
import PoweredByFingo from '../assets/images/powered-by-fingo.png';

const Enrollment = () => (
  <Stack spacing={2} alignItems="center">
    <Container variant="primary">
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={4} alignSelf="flex-start">
          <Stack spacing={2}>
            <Title title={ENROLMENT_TITLE} sx={{ width: '386px', mb: '4px' }} />
            {ENROLLMENT_BODY.map(({ text, sx }) => (
              <Typography
                key={text}
                variant="body1"
                color="secondary"
                sx={{ maxWidth: '345px', lineHeight: '29px', ...(sx || {}) }}
              >
                {text}
              </Typography>
            ))}
            <Box sx={{ m: 'auto !important', ml: '105px !important' }}>
              <img src={PoweredByFingo} alt="Powered By Fingo" width="155px" height="29px" />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <EnrollmentForm />
        </Grid>
      </Grid>
    </Container>
    <Container variant="secondary">
      <Stack spacing={2} alignItems="center" justifyContent="center">
        <Title title={TERMS_AND_CONDITIONS_TITLE} alignSelf="flex-start" />
        <TermsAndConditionsDocument />
      </Stack>
    </Container>
  </Stack>
);

export default Enrollment;
