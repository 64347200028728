import React from 'react';
import { Container } from '@mui/material';
import FingoLoader from '../../assets/gifs/fingo-loader.gif';

const Spinner = () => (
  <Container
    variant="primary"
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <img src={FingoLoader} alt="Loading..." width="400px" height="271px" />
  </Container>
);

export default Spinner;
