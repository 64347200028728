/* eslint-disable operator-linebreak */
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
  Alert,
  AlertTitle,
} from '@mui/material';
import { acceptedTermsVar } from '../graphql/reactive-variables';
import { Button } from './Layout';
import useEnroll from '../hooks/useEnroll';

const EnrollmentForm = () => {
  const [enroll, { disabled, loading, error, errorMessage, rut, password, setPassword }] =
    useEnroll();
  const checked = useReactiveVar(acceptedTermsVar);

  return (
    <Stack alignItems="center" justifyContent="center" spacing="10px">
      <Typography
        color="primary"
        fontWeight="bold"
        variant="caption"
        height="24px"
        mt="-75px"
        mb="4px"
      >
        {errorMessage}
      </Typography>
      <TextField
        id="rut"
        name="rut"
        label="RUT"
        variant="filled"
        value={rut}
        disabled
        mt="0 !important"
      />
      <Stack spacing={0} mt="35px !important">
        <TextField
          id="password"
          name="password"
          type="password"
          label="Clave SII"
          variant="filled"
          placeholder="••••••••"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          error={error}
          onKeyDown={(event) => {
            if (event.key === 'Enter' && checked && !disabled && !loading) {
              enroll();
            }
          }}
          required
        />
        <Typography color="secondary" variant="caption" ml={2}>
          Código de 8 a 10 caracteres
        </Typography>
      </Stack>
      <Button
        id="upload-credentials"
        type="submit"
        disabled={disabled || !checked}
        onClick={enroll}
        label="Registrar"
        loading={loading}
        sx={{ mt: '20px !important' }}
      />
      <FormGroup>
        <FormControlLabel
          control={(
            <Checkbox
              checked={checked}
              color="checkbox"
              inputProps={{ 'aria-label': 'controlled' }}
              onChange={(e) => {
                acceptedTermsVar(e.target.checked);
              }}
            />
          )}
          label="Acepto los Términos y Condiciones"
          color="secondary"
          componentsProps={{ typography: { variant: 'form' } }}
        />
      </FormGroup>
      <Alert severity="info">
        <AlertTitle mb={0}>Serás redireccionado a Fingo</AlertTitle>
      </Alert>
    </Stack>
  );
};

export default EnrollmentForm;
