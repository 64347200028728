import React from 'react';
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import { NavigateNext } from '@mui/icons-material';

const Button = ({ disabled, label, loading, onClick, ...props }) => (
  <LoadingButton
    color="primary"
    disabled={disabled}
    endIcon={<NavigateNext />}
    loading={loading}
    onClick={onClick}
    variant="contained"
    {...props}
  >
    {label}
  </LoadingButton>
);

Button.propTypes = {
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

Button.defaultProps = {
  loading: false,
};

export default Button;
